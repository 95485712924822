import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from '../animations/animation';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
  animations: [fadeInAnimation],
  host: {'[@fadeInAnimation]': ''}
})
export class GalleryComponent implements OnInit {
  state: string = 'active';
  
  private residentialImageUrl = "/assets/images/projects/residential/";
  private commercialImageUrl = "/assets/images/projects/commercial/";

  private numCommercial = 19;
  private numResidential = 32;

  private imageUrls = [];

  constructor(
  ) { }

  ngOnInit() {

    for (var i = 1; i <= this.numResidential; i++) {
      this.imageUrls.push(this.residentialImageUrl + "" + i + ".jpg");
    }

    for (var i = 1; i <= this.numCommercial; i++) {
      this.imageUrls.push(this.commercialImageUrl + "" + i + ".jpg");
    }
  }

}
