export class ContactForm {
    public name: string;
    public phone: number;
    public email: string;
    public message: string;

    constructor() {
        
    }
}
