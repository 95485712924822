import { Component, OnInit, Input } from '@angular/core';
import { ContactForm } from '../contact-form';
import { PostsService } from '../posts.service';
import { fadeInAnimation } from '../animations/animation';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' }
})
export class ContactUsComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  spinner: boolean = false;

  state: string = 'active';
  constructor(private postsService: PostsService,
    public dialog: MatDialog
  ) { }
  form: ContactForm;

  ngOnInit() {
    this.form = new ContactForm();
  }

  contactInfo = {
    phone: "(941) 456-3775",
    altPhone: "(941) 625-0005",
    fax: "(941) 764-3476",
    owner: "Anwar Tabri",
    email: "tabriconstruction1@hotmail.com",
    mailingAddress: {
      addr: "PO BOX 494408",
      city: "Port Charlotte",
      state: "Florida",
      stateAbbr: "FL",
      zip: "33948"
    },
    open: {
      days: "Mon - Fri",
      time: "8:00 am - 5:00 pm"
    },
    closed: {
      days: "Sat - Sun",
      time: "Closed"
    }
  }

  formLabels = {
    name: "Name",
    phone: "Phone",
    email: "Email",
    message: "Message"
  }

  submitContactForm(form: NgForm) {
    this.spinner = true;
    this.postsService.sendEmail(this.form);
    let dialogRef = this.dialog.open(SendMessageConfirmationDialog);
    this.spinner = false;
    form.resetForm();

  }
}

@Component({
  selector: 'dialog-result-example-dialog',
  templateUrl: 'send-message-confirmation-dialog.html',
})
export class SendMessageConfirmationDialog implements OnInit {

  color = 'primary';
  mode = 'indeterminate';
  value = 50;

  constructor(
    public dialogRef: MatDialogRef<SendMessageConfirmationDialog>,
  ) { }

  ngOnInit() {

  }

}