import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from '../animations/animation';
@Component({
  selector: 'app-commercial',
  templateUrl: './commercial.component.html',
  styleUrls: ['./commercial.component.css'],
  animations: [fadeInAnimation],
  host: {'[@fadeInAnimation]':  ''}
})
export class CommercialComponent implements OnInit {

  state: string = 'active';
  public commercialBuildingImage = "/assets/images/projects/commercial/21.jpeg";
  
  // private commercialBuildingImage = "/assets/images/projects/commercial/3.jpg";
  public commercialBuildOutImage = "/assets/images/projects/commercial/15.jpg";
  public commercialRemodelImage = "/assets/images/projects/commercial/10.jpg";
  public commercialRemodelImage2 = "/assets/images/projects/commercial/11.jpg";
  public commercialRemodelBathroom = "/assets/images/projects/commercial/15.jpg";
  
  constructor() { }

  ngOnInit() {

  }

}
