import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from '../animations/animation';

@Component({
  moduleId: module.id.toString(),
  selector: 'app-residential',
  templateUrl: './residential.component.html',
  styleUrls: ['./residential.component.css'],
  animations: [fadeInAnimation],
  host: {'[@fadeInAnimation]': ''}
})
export class ResidentialComponent implements OnInit {

  state: string =  'active';
  show: boolean = true;

  public customhomeImage = "/assets/images/projects/residential/23.jpg";
  public remodelhomeImage = "/assets/images/projects/residential/21.jpg";
  public additionhomeImage = "/assets/images/projects/residential/27.jpg";

  constructor() { }

  ngOnInit() {
  }
  
}
