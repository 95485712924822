import { Injectable } from '@angular/core';
import { ContactForm } from './contact-form';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PostsService {

  constructor(private http: HttpClient) { }


  sendEmail(contactForm: ContactForm) {

    this.http.post('/api/sendmail', contactForm).subscribe((data) => {

    })
  }
}
