import { trigger, state, animate, transition, style } from '@angular/animations';

export const fadeInAnimation =
    trigger('fadeInAnimation', [
                transition(':enter', [
                    style({  opacity: 0 }),
                    animate('1000ms', style({  opacity: 1 }))
                ]),
                transition(':leave', [
                    style({  opacity: 1 }),
                    animate('1000ms', style({ opacity: 0 }))
                ])
            ]
        )
        // state('active',
        //     style({
        //         transform: 'scale(1)',
        //         backgroundColor: 'red'
        //     })
        // ),
        // state('inactive', 
        //     style({
        //         transform: 'scale(1)',
        //         backgroundColor: 'blue'
        //     })
        // ),
        // transition('inactive => active', animate('1000ms ease-in')),
        // transition('active => inactive', animate('1000ms ease-out'))
        //     // transition(':enter', [
        //     //     style({opacity: 0}),
        //     //     animate('5s', style({opacity: 1}))
        //     // ])

//     animations: [
//   trigger('heroState', [
//     state('inactive', style({
//       backgroundColor: '#eee',
//       transform: 'scale(1)'
//     })),
//     state('active',   style({
//       backgroundColor: '#cfd8dc',
//       transform: 'scale(1.1)'
//     })),
//     transition('inactive => active', animate('100ms ease-in')),
//     transition('active => inactive', animate('100ms ease-out'))
//   ])
// ]