import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ResidentialComponent } from './residential/residential.component';
import { CommercialComponent } from './commercial/commercial.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactUsComponent,SendMessageConfirmationDialog } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PostsService } from './posts.service';
import { MatDialogModule } from '@angular/material/dialog';

const appRoutes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'residential', component: ResidentialComponent
  },
  {
    path: 'commercial', component: CommercialComponent
  },
  {
    path: 'gallery', component: GalleryComponent
  },
  {
    path: 'contact-us', component: ContactUsComponent
  }
]
@NgModule({
  declarations: [
    AppComponent,
    ResidentialComponent,
    CommercialComponent,
    GalleryComponent,
    ContactUsComponent,
    HomeComponent,
    AboutComponent,
    SendMessageConfirmationDialog,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    MatDialogModule
  ],
  entryComponents: [
    SendMessageConfirmationDialog
  ],
  providers: [
    PostsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
