import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Tabri Construction';
  logo276x88 = "../assets/images/Tabri Construction Logo.jpg";
  
  constructionTypeLinks = [
    {
      text: 'Residential', routerLink: 'residential'
    },
    {
      text: 'Commercial', routerLink: 'commercial'
    }
  ]



  homeLink = {
    text: 'Home', routerLink: ''
  }

  contactUsLink = {
    text: 'Contact Us', routerLink: 'contact-us'
  }

  residentialLink = {
    text: 'Residential', routerLink: 'residential'
  }

  commercialLink = {
    text: 'Commercial', routerLink: 'commercial'
  }

  galleryLink = {
    text: 'Gallery', routerLink: 'gallery'
  }

  homePageLinks = [
    this.residentialLink,
    this.commercialLink,
    this.contactUsLink
  ]

  homePageInformation = [
    {
      title: 'Revolutionize Your Home',
      text: 'Tabri  Construction is happy to help fulfill your needs. Whether it is building your dream home or revnovating the interior, we are here to assist!',
      route: this.residentialLink,
    },
    {
      title: 'Renovate Your Business Space',
      text: 'Allow us to help build or remodel your commercial space! We will design it just the way you want!',
      route: this.commercialLink
    },
    {
      title: 'Contact Us Today for a FREE Consultation',
      text: 'Call today for a FREE Consultation on our services. With over 20 years of experience, we promise to steer you in the proper direction.',
      route: this.contactUsLink
    }
  ]
}
